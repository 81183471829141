<template>
  <div>
    <PageHeaderLayout>
      <div class="main-page-content">
        <el-row class="table-header">
          <el-col>
            <el-tooltip effect="dark" content="添加用户" placement="top-start"  v-if="userPermissions.indexOf('admin_create') != -1 && buttonType=='icon'" >
              <el-button type="primary" size="medium" icon="iconfont icon-tianjiacaidan2" @click="addButton()"></el-button>
            </el-tooltip>
            <el-button type="primary" size="medium"  v-if="userPermissions.indexOf('admin_create') != -1 && buttonType=='text'" @click="addButton()">添加</el-button>
          </el-col>
        </el-row>
        <el-row class="table-search">
          <el-form size="medium" :inline="true" :model="searchCondition" class="demo-form-inline">
            <el-form-item label="用户名">
              <el-input v-model="searchCondition.username" placeholder="输入用户名" clearable></el-input>
            </el-form-item>
            <el-form-item label="手机号">
              <el-input v-model="searchCondition.mobile" placeholder="输入手机号" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searchButton">查询</el-button>
            </el-form-item>
          </el-form>
        </el-row>
        <ApeTable ref="apeTable" :data="userList" :columns="columns" :loading="loadingStaus" :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row>
          <el-table-column
            slot="first-column"
            width="64"
            label="序号">
            <template slot-scope="scope">
              <span>{{offset+scope.$index+1}}</span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            width="120"
            label="头像">
            <template slot-scope="scope">
              <img :src="scope.row.avatar" style="width: 64px; height:64px;">
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            width="120"
            label="授权码">
            <template slot-scope="scope">
              <img :src="scope.row.authcode_img" style="width: 64px; height:64px;">
            </template>
          </el-table-column>
          <el-table-column
            v-if="buttonType=='icon'"
            width="200"
            label="操作">
            <template slot-scope="scope">
              <el-tooltip effect="dark" content="重置验证码" placement="top-start" >
                <el-button size="medium" type="danger" icon="el-icon-lock" @click="resetAuthCode(scope.row)"></el-button>
              </el-tooltip>
              <el-tooltip effect="dark" content="编辑" placement="top-start"  v-if="userPermissions.indexOf('admin_edit') != -1" >
                <el-button size="medium" icon="el-icon-edit" @click="editButton(scope.row.id)"></el-button>
              </el-tooltip>
              <el-tooltip effect="dark" content="禁用" placement="top-start"  v-if="userPermissions.indexOf('admin_edit') != -1&&scope.row.allow_login=='2'" >
                <el-button size="medium" icon="el-icon-open" @click="rowSubmit(scope.row)"></el-button>
              </el-tooltip>
              <el-tooltip effect="dark" content="启用"  placement="top-start"  v-if="userPermissions.indexOf('admin_edit') != -1&&scope.row.allow_login=='1'" >
                <el-button size="medium" icon="el-icon-turn-off" @click="rowSubmit(scope.row)"></el-button>
              </el-tooltip>
              <el-tooltip effect="dark" content="删除" placement="top-start">
                <span>
                  <el-popover
                    v-if="userPermissions.indexOf('admin_delete') != -1"
                    placement="top"
                    width="150"
                    v-model="scope.row.visible">
                    <p>确定要删除记录吗？</p>
                    <div style="text-align: right; margin: 0;">
                      <el-button type="text" size="medium" @click="scope.row.visible=false">取消</el-button>
                      <el-button type="danger" size="medium" @click="deleteButton(scope.row.id)">确定</el-button>
                    </div>
                    <el-button slot="reference" type="danger" size="medium" icon="el-icon-delete"></el-button>
                  </el-popover>
                </span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            v-if="buttonType=='text'"
            label="操作">
            <template slot-scope="scope">
              <span>
                <el-button size="medium"  v-if="userPermissions.indexOf('admin_edit') != -1" @click="editButton(scope.row.id)">编辑</el-button>
                <el-popover
                  v-if="userPermissions.indexOf('admin_delete') != -1"
                  placement="top"
                  width="150"
                  v-model="scope.row.visible">
                  <p>确定要删除记录吗？</p>
                  <div style="text-align: right; margin: 0;">
                    <el-button type="text" size="medium" @click="scope.row.visible=false">取消</el-button>
                    <el-button type="danger" size="medium" @click="deleteButton(scope.row.id)">确定</el-button>
                  </div>
                  <el-button slot="reference" type="danger" size="medium" >删除</el-button>
                </el-popover>
              </span>
            </template>
          </el-table-column>
        </ApeTable>
      </div>
    </PageHeaderLayout>

    <ApeDrawer :drawerData="drawerData"  @drawerClose="drawerClose" @drawerConfirm="drawerConfirm">
      <template slot="ape-drawer">
        <el-form :model="formData" :rules="rules" ref="userForm" label-position="right" label-width="96px">
          <el-row>
            <el-col :span="22">
              <el-form-item label="用户名" prop="username">
                <el-input v-model="formData.username"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="手机号" prop="mobile">
                <el-input v-model="formData.mobile"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="Email" prop="email">
                <el-input v-model="formData.email"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="工号" prop="job_number">
                <el-input v-model="formData.job_number"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="密码" prop="password">
                <el-input v-model="formData.password" type="password"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="管理员头像"> 
                 <ApeUploader :limit="1" @handleUploadSuccess="handleUploadSuccess" @handleUploadRemove="handleUploadRemove" :upload-file-list="uploadFileList"></ApeUploader> 
              </el-form-item>
            </el-col>
          </el-row>
           <el-row>
            <el-col :span="22">
              <el-form-item label="二次验证码" prop="password">
                <img :src="formData.authcode_img" style="width:200px;heigth:200px">
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>     
      </template>
    </ApeDrawer>
  </div >
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import ApeDrawer from '@/components/ApeDrawer'
import { mapGetters } from 'vuex'
import ApeUploader from '@/components/ApeUploader'
export default {
  components: {
    PageHeaderLayout,
    ApeTable,
    ApeDrawer,
    ApeUploader
  },
  data() {
    return {
      // 搜索条件
      searchAdminCondition:{
        current_page: 1,
        page_size: 200,
        query: "",
        params: [],
      },
      searchCondition:{
        username:'',
        mobile:''
      },
      searchRolesCondition: {
        current_page: 1,
        page_size: 200,
        query: "",
        params: [],
      },
      loadingStaus: true,
      columns: [
        {
          title: '用户名',
          value: 'username',
          width: 240
        },
        {
          title: '昵称',
          value: 'nickname'
        },
        {
          title: '手机号',
          value: 'mobile'
        },
        {
          title: '邮箱',
          value: 'email'
        },
        {
          title: '工号',
          value: 'job_number'
        },

      ],
      uploadFileList:[],
      // 表格列表数据
      userList:[],
      // 分页信息
      pagingData:{
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      // 分页的offset,序号列使用
      offset:0,
      // 表单结构
      formData: {
        avatar:''
      },
      // 表单验证
      rules: {
        mobile: [{required: true, message: '输入手机号', trigger: 'blur' },{pattern: this.$utils.regular.mobile,message:'手机号格式错误', trigger: 'blur' }],
        username: [{max:20,min:6,required: true,message:'用户名格式6-20位英文数字', trigger: 'blur' }],
        nickname: [{max:20,min:2,required: true,message:'昵称为2-20位', trigger: 'blur' }],
        email: [{ type: 'email',max:20,min:6, required: true,message:'邮箱为6-20位', trigger: 'blur' }],
        job_number: [{ required: true,max:20,min:3,message:'工号为3-20位', trigger: 'blur' }],
        password: [
          { pattern:/^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$)([^\u4e00-\u9fa5\s]){8,20}$/,required: true, message: '必须输入8-20且字母、数字和标点符号至少包含两种', trigger: 'blur' }
        ],
      },
      // 抽屉数据
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        title: '权限绑定',
        width_height: '480px',
      },
      rolesList: [],
      userRoles: []
      
    }
  },
  computed: {
    ...mapGetters(['userPermissions','buttonType'])
  },
  methods: {
    // 搜索
    searchButton() {
      this.searchAdminCondition.query= ''
      this.searchAdminCondition.params = []

      if (this.searchCondition.username != ''){
        this.searchAdminCondition.query = ' instr(username,?)'
        this.searchAdminCondition.params.push(this.searchCondition.username)
      }
      if (this.searchAdminCondition.query !=''){
        if(this.searchCondition.mobile !=''){
          this.searchAdminCondition.query += ' and instr(mobile,?)'
          this.searchAdminCondition.params.push(this.searchCondition.mobile)
        }
      }else{
        this.searchAdminCondition.query = ' instr(mobile,?) '
        this.searchAdminCondition.params.push(this.searchCondition.mobile)
      }
      this.initAdminList()
    },
    // 切换页码操作
    async switchPaging() {
      this.initAdminList()
    },
    // 响应添加按钮
    async addButton() {
      this.uploadFileList=[]
      this.formData = {}
      this.drawerData.visible = true
      this.drawerData.title = '添加用户'
      this.drawerData.loading = false
    },
    // 响应编辑按钮
    async editButton(id) {
      this.uploadFileList=[]
      this.drawerData.loading_text = '玩命加载中……'
      this.drawerData.visible = true
      this.drawerData.title = '编辑用户(UID：'+id+')'
      let {info} = await this.$api.getAdminInfo(id)
      this.formData = info
      if (info.avatar !='' ){
        this.uploadFileList = [{id:info.id,name:info.nickname,url:info.avatar}]
      }
      this.drawerData.loading = false
    },
    // 处理抽屉关闭
    drawerClose() {
      this.initFormData()
      this.drawerData.visible = false
      this.drawerData.loading = true
      this.rolesList = []
    },
    // 处理抽屉确认
    async drawerConfirm() {
      // 调用组件的数据验证方法
      this.$refs['userForm'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
    },
    // form数据提交，请求接口
    async formSubmit() {
      this.drawerData.loading_text = '玩命提交中……'
      this.drawerData.loading = true
      let id = await this.$api.saveAdmin(this.formData)
      this.$nextTick(() => {
        this.drawerData.visible = false
      })
      this.$nextTick(async () => {
        if (id) {
          this.initAdminList()
        }
      })
      this.$nextTick(() => {
        this.$message.success('保存成功!')
      })
      this.initFormData()
    },
    // row数据提交，请求接口
    async rowSubmit(row) {
      this.drawerData.loading_text = '玩命提交中……'
      this.drawerData.loading = true
      if(row.allow_login=='1'){
        row.allow_login='2'
      }else{
        row.allow_login='1'
      }
      let id = await this.$api.saveAdmin(row)
      this.$nextTick(() => {
        this.drawerData.visible = false
      })
      this.$nextTick(async () => {
        if (id) {
          this.initAdminList()
        }
      })
      this.$nextTick(() => {
        this.$message.success('保存成功!')
      })
      this.initFormData()
    },
    async resetAuthCode(row) {
      row.authcode = ""
      this.drawerData.loading_text = '玩命提交中……'
      this.drawerData.loading = true
      if(row.allow_login=='1'){
        row.allow_login='2'
      }else{
        row.allow_login='1'
      }
      let id = await this.$api.saveAdmin(row)
      this.$nextTick(() => {
        this.drawerData.visible = false
      })
      this.$nextTick(async () => {
        if (id) {
          this.initAdminList()
        }
      })
      this.$nextTick(() => {
        this.$message.success('保存成功!')
      })
      this.initFormData()
    },
    // 相应删除按钮
    async deleteButton(id) {
      let info = await this.$api.deleteAdmin(id)
      if (info.id == id) {
        this.$nextTick(async () => {
          this.initAdminList()
        })
      } else {
        this.$message.error(info)
      }
      
    },
    // 初始化数据
    initFormData() {
      // 初始化form表单
      this.$nextTick(() => {
        this.formData = {user_role:{system_role_id:0}}
        this.$refs['userForm'].resetFields()
      })
    },
    // 图片上传成功回调
    handleUploadSuccess(file, fileList) {
      this.formData.avatar = file
      this.uploadFileList = fileList
    },
    // 图片删除回调
    handleUploadRemove(file, fileList) {
      this.formData.image = 0
      this.uploadFileList = fileList
    },
    // 初始化用户列表
    async initAdminList() {
      this.loadingStaus=true
      let {list,pages}= await this.$api.getAdminList(this.searchAdminCondition)
      this.userList=list
      this.pagingData.total = pages.total
      this.offset = pages.offset
      this.loadingStaus=false
    }
  },
  mounted() {
    this.initAdminList()
  },
}
</script>

<style lang="stylus">
  .el-button
    margin-right 4px
    margin-bottom 4px
  .table-header
    margin-bottom 12px
  .drag-handle
    font-size 24px
    cursor pointer
  .el-input-group__prepend, .el-input-group__append
    background #ffffff
    padding 0 12px
  .el-input-group__append
    color #ffffff
    background #1890ff
  .el-popover .el-checkbox-group .el-checkbox
    margin-left 0px
    margin-right 12px
  .el-select > .el-input 
    width 300px
</style>
